<template>
  <div class="text-center">
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setVesselsToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setVesselsToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setVesselsToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-3 pb-2">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="requestPayload.search" placeholder="Search Vessel">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle" style="width: 1em" scope="col">#</th>
<!--            <th class="align-middle no-select cursor-pointer" scope="col" @click="sorterArrowSetDirection('name')">-->
            <th class="align-middle no-select cursor-pointer" scope="col" style="padding: 0; position: relative">
              SHIP NAME

              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_list'"
                :column-name="'name'"
                :filter-option-to-right="true"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle no-select cursor-pointer" scope="col" style="padding: 0; position: relative">
              FLAG
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_list'"
                :column-name="'flag_id'"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle no-select cursor-pointer" scope="col" style="padding: 0; position: relative">
              MANAGEMENT
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_list'"
                :column-name="'management_company_id'"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th class="align-middle no-select cursor-pointer" scope="col" style="padding: 0; position: relative">
              FLEET
              <filter-like-excel
                v-show="loading === false"
                :module="'vessel_list'"
                :column-name="'fleet_id'"
                @setFilterParam="setFilterParam"
              />
            </th>
            <th style="width: 70px" scope="col">ACTION</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <tr v-for="(vessel, index) in vesselList" :key="vessel.id" class="ia-vessel-list no-select" :title="vessel.name" @dblclick="viewVesselAuditRecord(vessel.id)">
              <td class="align-middle">{{ index + pagination.from }}</td>
              <td class="align-middle text-uppercase">{{ vessel.name }}</td>
              <td class="align-middle">{{ vessel.flag ? vessel.flag.name : '' }}</td>
              <td class="align-middle">{{ vessel.management_company ? vessel.management_company.name : '' }}</td>
              <td class="align-middle">{{ vessel.fleet ? vessel.fleet.name : '' }}</td>
              <td class="align-middle py-1">
                <div class="dropdown dropright">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="viewVesselAuditRecord(vessel.id)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="viewVesselAuditRecordNewTab(vessel.id)">
                      <font-awesome-icon icon="eye"/>
                      View on New Tab
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import SorterArrow from "@/components/common/SorterArrow.vue";
import FilterLikeExcel from "@/components/common/FilterLikeExcel.vue";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin],
  components: {AppLoading, SorterArrow, FilterLikeExcel},

  name: 'VesselList',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        search:'',
      },
      requestPayload : {
        filter : {},
        sort : '',
        page : 1,
        active: 1,
        search: '',
      },

      allModuleFilterParams : [],
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'getVessels',
      'deleteVessel'
    ]),
    setFilterParam(filterParam, dataLength) {
      const moduleFilterParamIndex = this.allModuleFilterParams.findIndex(item => item.column_name === filterParam.column_name);
      if (moduleFilterParamIndex === -1) {
        if (filterParam.direction === '' && filterParam.where_in.length === 0) {
          return;
        }
        this.allModuleFilterParams.push(filterParam);
      } else {
        if (filterParam.direction === '' && filterParam.where_in.length === dataLength) {
          this.allModuleFilterParams.splice(moduleFilterParamIndex, 1)
        } else {
          this.allModuleFilterParams[moduleFilterParamIndex] = filterParam;
        }
      }

      this.requestPayload.filter = {};
      this.allModuleFilterParams.forEach(item => {
        if (item.column_name !== filterParam.column_name) {
          item.direction = '';
        }
        this.requestPayload.filter[item.column_name] = item.where_in;
      });

      const sortIndex = this.allModuleFilterParams.findIndex(item => item.direction !== '');
      if (sortIndex === -1) {
        this.setVesselsToTable(1);
        return;
      }
      const filterParamWithSort = this.allModuleFilterParams[sortIndex];
      const sortSign = filterParamWithSort.direction === 'desc' ? '-' : '';
      this.requestPayload.sort = sortSign + filterParamWithSort.column_name;
      this.setVesselsToTable(1);
    },
    async viewVesselAuditRecord(vesselAuditRecordId) {
      await this.$router.push(
        {
          name: 'VesselAuditRecordList',
          params: {
            vessel_id: vesselAuditRecordId,
          },
        },

      ).catch(() => {})
    },
    async viewVesselAuditRecordNewTab(vesselAuditRecordId) {
      const route= this.$router.resolve(
        {
          name: 'VesselAuditRecordList',
          params: {
            vessel_id: vesselAuditRecordId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async setVesselsToTable(page=1) {
      this.loading = true;
      this.requestPayload.page=page
      await this.getVessels(this.requestPayload)
      this.loading = false;
    },
    runCallBack(param) {
      this.params.orderBy = param.orderBy;
      this.params.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setVesselsToTable(1);
    }
  },
  computed: {
    ...mapGetters([
      'pagination',
      'vesselList',
      'isLoading',
    ]),
  },
  async created() {
    this.setVessels = _.debounce(this.setVesselsToTable, 750)
    await this.setVesselsToTable();
  },
  watch: {
    'requestPayload.active'() {
      this.setVessels()
    },
    'requestPayload.search'(){
      this.setVessels();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped lang="scss">
</style>
